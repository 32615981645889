import React from 'react';
import Table from "./../../components/Table";
import TableHead from "./../../components/Table/TableHead";
import TableBody from "./../../components/Table/TableBody";
import config from "./../../config";
import fetchData from "./../../services/fetchData";
import "./Dashboard.css"

export default function Dashboard() {

  const rows = [
      { id: 1, name: "Paciente" },
      { id: 2, name: "Alta" },
      { id: 3, name: "Estatus" }
  ];

  const [elements, setElements] = React.useState([]);

  function getPacientes(){
    let userData = JSON.parse(localStorage.getItem('user'))
    let data = {
      idServicio : userData.id_servicio
    }
    console.log({data})
    fetchData('GET',`${config.urlApi}cita/beforeAttending/${userData.id_servicio}`, data)
      .then(response => {
        console.log({response})
        setElements(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  React.useEffect(() => {
    getPacientes()
    setInterval(function() {
      getPacientes()
    }, 10000);
  } , []);

  return (
    <section>
      {
        elements.length > 0 
        ?
        <div className="container text-center my-4">
          <h1>Pacientes en sala de espera</h1>
          <p>Puedes comenzar la teleorientación de los siguientes pacientes:</p>
          <Table>
            <TableHead rows={rows} />

            <TableBody elements={elements}  />
          </Table>
        </div>
        :
        <div className="container text-center my-4">
          <h1>Sin pacientes en la sala de espera</h1>
          <p>Aquí se mostrarán los pacientes que deberás atender.</p>
        </div>
      }
      
      <div id="wrapper"></div>
    </section>
  );
}