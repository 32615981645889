import React from 'react';
import ReactDOM from 'react-dom';
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import './Receta.css'
import { useLocation } from "react-router-dom";
import fetchData from './../../services/fetchData';
import config from "./../../config";
import Swal from 'sweetalert2'
import createPdfReceta from './../../services/createPdfReceta';
import $ from 'jquery';
const URLAPI = config.urlApi;

function byteToHex(byte) {
    return ('0' + byte.toString(16)).slice(-2);
}
function generateId(len = 40) {
    var arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, byteToHex).join("");
}

export default function Receta(){
    const location = useLocation();
    const idCita = location.pathname.split('/')[2];
    const [loadingData, setLoadingData] = React.useState(true);
    const [dataCita, setDataCita] = React.useState({});
    const [base64Qr, setBase64Qr] = React.useState(false);

    const [medicamentosReceta, setMedicamentosReceta] = React.useState([]);

    React.useEffect(() => {
        // Tenemos que esperar 1 seg para realizar la consulta
        setTimeout(function(){
            getDatosDeCita()
        }, 2000);
    } , []);

    const getDatosDeCita = () => {
        fetchData('GET', `${URLAPI}cita/${idCita}`)
            .then(data => {
                setDataCita(data[0])
                setLoadingData(false)
                document.getElementById("btnEnviarReceta").disabled = true;
            })
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    const onClickAgregarMedicamento = () => {
        if(document.getElementById("formularioAgregarMedicamento").classList.contains("hide")){
            document.getElementById("formularioAgregarMedicamento").classList.remove("hide")
        } else {
            document.getElementById("formularioAgregarMedicamento").classList.add("hide")
        }
    }

    const onSubmitFormularioAgregarMedicamento = (e) => {
        e.preventDefault();
        // OBTENEMOS LOS VALORES DEL FORMULARIO
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        // AGREGAMOS EL NUEVO MEDICAMENTO A LA LISTA
        let medicamentos = [...medicamentosReceta];
        medicamentos.push(objectValues);
        if(medicamentos.length >= 3)
            document.getElementById("btn-agregarMedicamento").classList.add("hide");
        // ACTUALIZAMOS EL ESTADO DE LA LISTA DE MEDICAMENTOS
        setMedicamentosReceta(medicamentos);
        // OCULTAMOS EL FORMULARIO Y LO RESETEAMOS
        document.getElementById("formularioAgregarMedicamento").reset();
        document.getElementById("formularioAgregarMedicamento").classList.add("hide");

        // HABILITAMOS EL BOTON PARA ENVIAR LA RECETA
        document.getElementById("btnEnviarReceta").disabled = false;
        console.log(medicamentos);
    }

    const onClickBorrarListaMedicamentos = () => {
        setMedicamentosReceta([]);
        // DESHABILITAMOS EL BOTON PARA ENVIAR LA RECETA
        document.getElementById("btnEnviarReceta").disabled = true;
        document.getElementById("btn-agregarMedicamento").classList.remove("hide");
    }

    const onClickOmitirReceta = () => {
        Swal.fire({
            title: "¿Estás seguro?",
            showCancelButton: true,
            confirmButtonText: "Si, omitir receta",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                window.location.href = '/';
            }
        })
    }

    const onClickEnviarReceta = () => {
        Swal.fire({
            title: "Se enviará la receta al paciente",
            showCancelButton: true,
            confirmButtonText: "Si, enviar receta",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                altaReceta()
            }
        })
    }

    const generateQRCode = (uniqueId) => {
        let date = new Date();
        ReactDOM.render(
            <QRCodeCanvas 
            value={`Cedula Profesional: ${dataCita.cedula_doctor}|Medico: ${dataCita.nombre_doctor + " " + dataCita.apellido_materno_doctor + " " + dataCita.apellido_paterno_doctor}|Especialidad: ${dataCita.especialidad}|Fecha: ${date.getDate()}/${date.getMonth()}/${date.getFullYear()}|Hora: ${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}|ID: ${uniqueId}`}
            bgColor="#FFFFFF" 
            fgColor="#000000" 
            size="1024" 
            level="H"
            id="fuckingCode" />,
            document.getElementById('qrCode')
        )
        setBase64Qr(true)
    }

    const altaReceta = async () => {

        Swal.isLoading()

        let uniqueId = generateId(256)
        await generateQRCode(uniqueId)
        const qrCodeCanvas = document.getElementById('fuckingCode');
        const qrCodeDataUri = qrCodeCanvas.toDataURL('image/png', 1);

        const data = {
            idCita: idCita,
            edad: document.getElementById('edad').value,
            talla: document.getElementById('talla').value,
            peso: document.getElementById('peso').value,
            observaciones: document.getElementById('anotaciones').value,
            sexo: document.getElementById('sexo').value,
            uniqueId: uniqueId,
        }

        fetchData('POST', `${URLAPI}receta`, data)
            .then(async response => {
                console.log(response);
                let idReceta = response.id;
                medicamentosReceta.forEach(medicamento => {
                    let dataMedicamento = {
                        idReceta: idReceta,
                        nombre: medicamento.nombre,
                        presentacion: medicamento.presentacion,
                        cantidad: medicamento.cantidad,
                        cantidadHora: medicamento.cantidad_hora,
                        duracionDias: medicamento.duracion_dias,
                    }
                    fetchData('POST', `${URLAPI}receta/medicamento`, dataMedicamento)
                        .then(response => {
                            console.log(response)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })

                // DECLARAMOS TODOS LOS DATOS QUE ENVIAREMOS A LA RECETA
                let dataReceta = {
                    nombrePaciente: dataCita.nombre + " " + dataCita.apellido_materno + " " + dataCita.apellido_paterno,
                    edad: data.edad,
                    sexo: data.sexo,
                    talla: data.talla,
                    peso: data.peso,
                    observaciones: data.observaciones,
                    nombreDoctor: dataCita.nombre_doctor + " " + dataCita.apellido_paterno_doctor + " " + dataCita.apellido_materno_doctor,
                    cedulaDoctor:  dataCita.cedula_doctor,
                    firmaDoctor: dataCita.url_firma_doctor,
                    nombreEscuelaDoctor:  dataCita.nombre_escuela_doctor,
                    logoEscuela: dataCita.url_logo_escuela_doctor,
                    nombreServicio: dataCita.nombre_servicio,
                    nombreServicioMotivo: dataCita.nombre_servicio_motivo,
                    medicamentosReceta: medicamentosReceta,
                    base64Qr: qrCodeDataUri,
                    uniqueId: uniqueId,
                }
                let blob = await createPdfReceta(dataReceta)

                let formData = new FormData();
                formData.append('file', blob, 'receta.pdf');
                formData.append('correo', dataCita.correo);
                formData.append('nombrePaciente', dataCita.nombre + " " + dataCita.apellido_materno + " " + dataCita.apellido_paterno);

                $.ajax({
                    url: `${URLAPI}receta/enviar`,
                    type: 'POST',
                    data: formData,
                    enctype: 'multipart/form-data',
                    processData: false,
                    contentType: false,
                    success: function (response) {
                        console.log(response)
                    }
                })
                
                Swal.fire({
                    title: "Receta enviada",
                    text: "La receta ha sido enviada al paciente",
                    icon: "success",
                }).then(() => {
                    window.location.href = '/';
                })
                // var _url = URL.createObjectURL(blob);
                // window.open(_url)

            })
            .catch(err => {
                console.log(err);
            })

        
    }

    return(
        <div className="container-fluid">

            <div className="container my-5">

                <div className={ `${!loadingData && "hide"} text-center`}>
                    <div className="lds-heart"><div></div></div>
                    <p>Cargando datos de la cita...</p>
                </div>

                <div className={ `${loadingData && "hide"} text-center`}>
                    <h1>
                        Genera una receta para tu paciente
                    </h1>
                    <p>
                        Tu siguiente paso es generar la receta correspondiente, sí no es necesario, puedes omitirlo con el botón de &nbsp;
                        <span className="font-weight-bold">Omitir envío de receta</span>
                    </p>
                </div>

                <div className={ `${loadingData && "hide"} row my-5`}>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div className="datos-generales-receta">
                            <h3>Datos generales de la receta</h3>

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>Sexo:</label>
                                    <select className="input" name="sexo" id="sexo">
                                        <option value="M">Masculino</option>
                                        <option value="F">Femenino</option>
                                    </select>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>Edad:</label>
                                    <input className="input" placeholder="En cantidad de años" type="text" name="edad" id="edad" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>Peso:</label>
                                    <input className="input" placeholder="En kilogramos" type="text" name="peso" id="peso"/>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <label>Talla:</label>
                                    <input className="input" placeholder="En centímetros" type="text" name="talla" id="talla"/>
                                </div>
                            </div>
                        </div>

                        {/* ****************************************************** */}
                        <hr className="my-3"></hr>

                        <div className="lista-medicamentos">
                            <div className="d-flex justify-content-between">
                                <h3>Medicamentos</h3>
                                <button className={`btn btn-danger ${ medicamentosReceta.length === 0 && 'hide' }`} onClick={onClickBorrarListaMedicamentos}>
                                    Borrar todo
                                </button>
                            </div>
                            <ul className="mt-2">
                                {
                                    medicamentosReceta.map((medicamento, index) => {
                                        return(
                                            <li key={index}>
                                                {medicamento.nombre} {medicamento.presentacion} -&nbsp;
                                                <b>{medicamento.cantidad} cada {medicamento.cantidad_hora} hrs durante {medicamento.duracion_dias} días</b>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <button className={`btn btn-agregarMedicamento`} id="btn-agregarMedicamento" onClick={onClickAgregarMedicamento}>
                            Añadir medicamento
                        </button>

                        <form className="hide my-3" id="formularioAgregarMedicamento" onSubmit={ onSubmitFormularioAgregarMedicamento }>
                            <hr className="my-3"></hr>
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nombre del medicamento:</label>
                                    <input className="input" type="text" name="nombre" required />
                                </div>
                                
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Presentación:</label>
                                    <input className="input" type="text" name="presentacion" required />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label>Dosis:</label>
                                    <input className="input" placeholder="Cantidad" type="number" name="cantidad" required />
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label>Frecuencia:</label>
                                    <input className="input" placeholder="Por hora" type="number" name="cantidad_hora" required />
                                </div>
                                
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                    <label>Duración:</label>
                                    <input className="input" placeholder="En días" type="number" name="duracion_dias" required />
                                </div>
                            </div>

                            <button className="btn btn-info">
                                Añadir
                            </button>
                        </form>
                        
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <p>
                            <label className='font-weight-bold'>Nombre del paciente:</label> {`${dataCita.nombre} ${dataCita.apellido_paterno} ${dataCita.apellido_materno}`}
                        </p>
                        <p>
                            <label className='font-weight-bold'>Especialidad solicitada:</label> {`${dataCita.nombre_servicio}`}
                        </p>
                        <p>
                            <label className='font-weight-bold'>Motivo:</label> {`${dataCita.nombre_servicio_motivo}`}
                        </p>
                        <p>
                            <label className='font-weight-bold'>Mensaje del paciente:</label> {`${dataCita.mensaje}`}
                        </p>
                        <hr className="my-3"></hr>
                        <label className="font-weight-bold">Observaciones:</label>
                        <p>
                            {`${dataCita.observaciones}`}
                        </p>
                        <hr className='my-3'></hr>
                        <label className="font-weight-bold">
                            Indicaciones y más:
                        </label>
                        <textarea name="anotaciones" id="anotaciones" className="input" maxLength="160"></textarea>
                    </div>
                </div>
                
                <div className={ `${loadingData && "hide"} pb-3`} >
                    <button className="btn btn-info btn-lg float-right mx-2" id="btnEnviarReceta" onClick={ onClickEnviarReceta }>
                        Enviar receta
                    </button>
                    <button className="btn btn-secondary btn-lg float-right mx-2" onClick={ onClickOmitirReceta }>
                        Omitir envío de receta
                    </button>
                </div>

                <div id="qrCode" className='hide'></div>

            </div>

        </div>
    )
}