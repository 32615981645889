import React from 'react';
import Swal from 'sweetalert2'
import DailyIframe from '@daily-co/daily-js';
import fetchData from "./../../services/fetchData";
import config from "./../../config";

export default function TableElement({ element }) {

    const { 
        idPaciente,
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        telefono,
        fechaAlta,
        idCitaEtapa,
        nombreCitaEtapa,
        nombreServicio,
        nombreMotivoServicio,
        otroMotivoConsulta,
        idCita
    } = element;

    const [hours, setHours] = React.useState("");

    async function getRoom(url){
      window.location.href = `/cita/${idCita}`;
      // const callWrapper = document.getElementById('wrapper');
      // let callFrame = await DailyIframe.createFrame(callWrapper);
      // try {
      //   callFrame.join({
      //     url: url,
      //     showLeaveButton: true,
      //     userName:"Doctor",
      //     theme: {
      //       colors: {
      //         accent: '#093548',
      //         accentText: '#FFFFFF',
      //         background: '#29AAE3',
      //         backgroundAccent: '#FBFCFD',
      //         baseText: '#000000',
      //         border: '#EBEFF4',
      //         mainAreaBg: '#000000',
      //         mainAreaBgAccent: '#333333',
      //         mainAreaText: '#FFFFFF',
      //         supportiveText: '#808080',
      //       },
      //     },
      //   });
      // } catch (e) {
      //   console.error(e);
      // }
    }

    const onClickAttendPacient = () => {
      let userData = JSON.parse(localStorage.getItem('user'))
      const data = {
        idDoctor: userData.id_doctor,
        telefono: telefono,
      }
      fetchData('PUT',`${config.urlApi}cita/${idCita}/addDoctor`, data)
        .then(response => {
          getRoom(response.url)
        })
        .catch(error => {
            console.log(error)
        })
    }

    React.useEffect(() => {
        let hour = fechaAlta.split('T')[1].split('Z')[0];
        setHours(hour)
    } , []);

    return (
        <tr key={idCita}>
            <th>
                <div className="table__name">
                    <div className="dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item" href="#" onClick={onClickAttendPacient}>Atender</a>
                            <a className="dropdown-item bg-danger text-white" href="#">Rechazar</a>
                        </div>
                    </div>

                    <div className="table__nameDescription">
                        <p>{`${nombre} ${apellidoPaterno} ${apellidoMaterno}`}</p>
                        <small> {nombreServicio}</small>
                        <br></br>
                        <small><span className="font-weight-bold">Detalle:</span> {(nombreMotivoServicio !== null) ? nombreMotivoServicio : otroMotivoConsulta}</small>
                    </div>
                </div>
            </th>

            <td>
                <div className="table__fechaAlta">
                    <p>{hours}</p>
                </div>
            </td>

            <td>
                <div className="table__estatus">
                    <span className={ idCitaEtapa === 1 ? '' : 'table__estatus-inactive' }>
                        { nombreCitaEtapa }
                    </span>
                </div>
            </td>
        </tr>
    )
    
}