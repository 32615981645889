import React from "react";
import "./Header.css";
import Icon from "./../Icon";
import avatar from "./../../assets/images/Avatar.png";
import logo from "./../../logo.png";
function Header(){

    //Evento para mostrar y ocultar menú
    function open_close_menu(){
    }

    const [user, setUser] = React.useState({});
    
    React.useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'))
        setUser(userData)
    }, []);

    function logout(){
        setUser({})
        localStorage.removeItem('user');
        window.location.reload();
    }

    return(
        <header>
            <div className="icon__menu" 
                onClick={ () => open_close_menu() }>

                    <img src={logo} alt="Hospitelec logo"/>

            </div>

            <div className="functions__menu">
                <div className="search__menu hide">
                    <Icon 
                        icon="fa-solid fa-search"
                    />
                    <input className="search" type="text" placeholder="Buscar"/>
                </div>
                <div className="profile__menu">
                    <img src={user.url_foto_perfil} alt="avatar"/>
                    <p>
                        {`${user.nombre} ${user.apellido_materno} ${user.apellido_paterno}`}
                    </p>
                </div>

                <div className="ml-3">
                    <button className="btn btn-info" onClick={logout}>
                        <Icon 
                            icon="fa-solid fa-arrow-right-from-bracket"
                        />
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;