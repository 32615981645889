import React from 'react';

// ESTILOS
import './App.css';

// Páginas
import HomePage from './pages/HomePage';
import Login from './pages/Login';

export default function App() {

  const [user, setUser] = React.useState({
    isLoggedIn: false,
    typeUser: 0,
    user : null
  });

  React.useEffect(() => {
    // Consultar localstorage para la sesión
    if(localStorage.getItem('user')){
        let userData = JSON.parse(localStorage.getItem('user'))
        setUser({
            isLoggedIn: true,
            typeUser: userData.id_tipo_usuario,
            user: userData
        })
    }

  },[]);

  return (
    <React.Fragment>
      {
        user.isLoggedIn ? <HomePage user={user} setUser={setUser} /> : <Login setUser={setUser} />
      }
    </React.Fragment>
  );
}