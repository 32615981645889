export default function TableHead({ rows }) {
    return (
        <thead>
            <tr>
                {rows.map(row => (
                    <th key={row.id}>{row.name}</th>
                ))}
            </tr>
        </thead>
    );
}