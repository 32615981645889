import './Table.css'

export default function Table({ children }){
    return(
        <table className="table table-hover">

            { children }

        </table>
    );
}