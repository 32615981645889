import React from 'react';

// Componentes
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import LateralMenu from "./../../components/LateralMenu";
import Header from "./../../components/Header";

// Páginas
import Dashboard from './../Dashboard';
import Cita from "./../Cita";
import Receta from "./../Receta";

export default function HomePage({user, setUser}) {

    return (
        <React.Fragment>            
            <Header/>
            <BrowserRouter>
                <Switch>
                    <Route path="/" exact> 
                        <Dashboard />
                    </Route>

                    <Route path="/cita/:id" exact>
                        <Cita />
                    </Route>

                    <Route path="/receta/:id" exact>
                        <Receta />
                    </Route>
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    );
}