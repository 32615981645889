import React from 'react'
import "./Cita.css"
import { useLocation, useHistory, Link } from "react-router-dom";
import fetchData from './../../services/fetchData';
import config from "./../../config";
import DailyIframe from '@daily-co/daily-js';
import Swal from 'sweetalert2'
const URLAPI = config.urlApi;

export default function Cita(){

    const location = useLocation();
    const history = useHistory();
    const idCita = location.pathname.split('/')[2];
    const [dataRoomCita, setDataRoomCita] = React.useState('');
    const [anotaciones, setAnotaciones] = React.useState('');
    const [isFinished, setIsFinished] = React.useState(false);

    React.useEffect(() => {
        createCallframe()
        // Tenemos que esperar 1 seg para realizar la consulta
        setTimeout(function(){
            getDatosDeCita()

        }, 3000);
        
    } , []);

    const getDatosDeCita = () => {
        fetchData('GET', `${URLAPI}cita/${idCita}`)
            .then(data => joinCall(data))
            .catch(err => {
                console.log("ha pteao", err);
            })
    }

    /* Main functions */
    let callFrame, room;

    async function createCallframe() {
        const callWrapper = document.getElementById('wrapper');
        callFrame = await DailyIframe.createFrame(callWrapper);

        callFrame
            .on('loaded', showEvent)
            .on('started-camera', showEvent)
            .on('camera-error', showEvent)
            .on('joining-meeting', toggleLobby)
            .on('joined-meeting', handleJoinedMeeting)
            .on('left-meeting', handleLeftMeeting);
    }

    async function joinCall(data) {
        console.log(data);
        if(data[0].fecha_exit !== null){
            window.location.href = `/receta/${idCita}`;
        }
        const urlCita = data[0].url_cita_room;
        setDataRoomCita(data[0]);
        const copyUrl = document.getElementById('copy-url');
        copyUrl.value = urlCita;
        // let userData = JSON.parse(localStorage.getItem('user'))
        // let nameDoctor = userData.nombre + " " + userData.apellido_paterno + " " + userData.apellido_materno
        let nameDoctor = "Doctor HPE";
        try {
            await callFrame.join({
                url: urlCita,
                showLeaveButton: true,
                userName:nameDoctor,
                theme: {
                  colors: {
                    accent: '#093548',
                    accentText: '#FFFFFF',
                    background: '#29AAE3',
                    backgroundAccent: '#FBFCFD',
                    baseText: '#000000',
                    border: '#EBEFF4',
                    mainAreaBg: '#000000',
                    mainAreaBgAccent: '#333333',
                    mainAreaText: '#FFFFFF',
                    supportiveText: '#808080',
                  },
                },
            });
        } catch (e) {
            if (
            e.message === "can't load iframe meeting because url property isn't set"
            ) {
                console.log('empty URL');
            }
            console.error(e);
        }
    }

    /* Event listener callbacks and helpers */
    function showEvent(e) {
        console.log('callFrame event', e);
    }

    function toggleLobby() {

    }

    function handleJoinedMeeting() {
        // CUANDO EL DOCTOR SE UNE A LA CONSULTA, COMENZAR EL CONTADOR DE TIEMPO
        showDemoCountdown();
    }

    function handleLeftMeeting() {
        // CUANDO FINALIZA LA CONSULTA DEL DOCTOR, HABILITAR EL MODULO DE RECETA Y FINALIZAR EL CONTADOR DE TIEMPO
        console.log("Ha finalizado la consulta")
        // AQUÍ HABILITAR EL BOTÓN DE GENERAR RECETA.
        setIsFinished(true);

        // MOSTRAR EL CONTADOR DE LA CITA COMO FINALIZADO.
        const countdownDisplay = document.getElementById('demo-countdown');
        clearInterval(window.expiresUpdate);
        window.expiresUpdate = null;
        countdownDisplay.innerHTML = 'Finalizado';

    }

    function copyUrl() {
        const url = document.getElementById('copy-url');
        const copyButton = document.getElementById('copy-url-button');
        url.select();
        document.execCommand('copy');
        copyButton.innerHTML = 'Copiado!';
    }

    function showDemoCountdown() {
        const countdownDisplay = document.getElementById('demo-countdown');
        if (!window.expiresUpdate) {
            let exp = Math.round(Date.now() / 1000) + 60 * 10;
            window.expiresUpdate = setInterval(() => {
                // console.log(window.expiresUpdate);
                // let exp = room && room.config && room.config.exp;
                if (exp) {
                    console.log(exp);
                    let seconds = Math.floor((new Date(exp * 1000) - Date.now()) / 1000);
                    let minutes = Math.floor(seconds / 60);
                    let remainingSeconds = Math.floor(seconds % 60);

                    console.log(remainingSeconds)

                    countdownDisplay.innerHTML = `Finaliza en ${minutes}:${
                    remainingSeconds > 10 ? remainingSeconds : '0' + remainingSeconds
                    }`;

                    if (seconds <= 0 && minutes <= 0) {
                        clearInterval(window.expiresUpdate);
                        window.expiresUpdate = null;
                        countdownDisplay.innerHTML = 'Finalizado';
                        finalizarCita()
                    }

                }
            }, 1000);
        }
    }

    function saveAnotaciones(value){
        setAnotaciones(value);
    }

    function sendAnotacionesAndGoToReceta(){
        document.getElementsByClassName("btn-generarReceta").disabled = true;
        let data = {
            anotaciones: anotaciones
        }
        console.log(data)
        fetchData('PUT', `${URLAPI}cita/${idCita}/anotaciones`, data)
            .then(data => {
                history.push(`/receta/${idCita}`);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function finalizarCita(){
        callFrame.leave();
    }

    return(
        <React.Fragment>
            <div className={ `${dataRoomCita !== "" && "hide"} text-center`}>
                <div className="lds-heart"><div></div></div>
                <p>Iniciando consulta...</p>
            </div>
            <div className={ `${dataRoomCita === "" && "hide"} contain`}>
                <div id="wrapper" className="wrapper"></div>
                <div id="call-controls-wrapper" className="call-controls-wrapper">
                    <div className="call-controls">
                        <div className="controls-header">
                            <h2>Detalles</h2>
                            <p id="demo-countdown" className="demo-countdown"></p>
                        </div>
                        <div className="controls-stats">
                            <p>
                                <span className='font-weight-bold'>Nombre del paciente:</span> {`${dataRoomCita.nombre} ${dataRoomCita.apellido_paterno} ${dataRoomCita.apellido_materno}`}
                            </p>
                            <p>
                                <span className='font-weight-bold'>Teléfono:</span> {`${dataRoomCita.telefono}`}
                            </p>
                            <p>
                                <span className='font-weight-bold'>Correo electrónico:</span> {`${dataRoomCita.correo}`}
                            </p>
                        </div>
                        <div className="controls-custom-example">
                            <p>
                                <span className='font-weight-bold'>Especialidad solicitada:</span> {`${dataRoomCita.nombre_servicio}`}
                            </p>
                            <p>
                                <span className='font-weight-bold'>Motivo:</span> {`${dataRoomCita.nombre_servicio_motivo}`}
                            </p>
                            <p>
                                <span className='font-weight-bold'>Mensaje del paciente:</span> {`${dataRoomCita.mensaje}`}
                            </p>
                        </div>
                        <div className="controls-anotaciones">
                            <div className="copy-url-instructions">
                                <h4>Observaciones</h4>
                                <p>Escribe aquí observaciones y datos que te servirán después, como la edad, talla y peso:</p>
                            </div>
                            <textarea onChange={ (e) => saveAnotaciones(e.target.value) } value={anotaciones} name="anotaciones" id="anotaciones" className="input"></textarea>
                        </div>

                        <div className={`controls-copy-url mb-3 ${ isFinished ? 'hidden' : '' }`}>
                            <hr className="mb-3 mt-1"></hr>
                            <div className="copy-url-action">
                                <label htmlFor="copy-url"></label>
                                <input
                                    type="text"
                                    className="url-input"
                                    id="copy-url"
                                    placeholder="Copy this room URL"
                                    pattern="^(https:\/\/)?[\w.-]+(\.(daily\.(co)))+[\/\/]+[\w.-]+$"
                                />
                                <button
                                    id="copy-url-button"
                                    className="button copy-url-button"
                                    onClick={copyUrl}
                                >
                                    Copiar URL
                                </button>
                            </div>
                        </div>

                        {/* No mostrarse sí la cita aún no finaliza */}
                        <div className={`controls-buttons text-center ${ !isFinished ? 'hidden' : '' }`}>
                            <button onClick={sendAnotacionesAndGoToReceta} className="btn-generarReceta my-3">Generar receta</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}