import pdfReceta from './../receta_hospitelec.pdf';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export default async function createPdfReceta(data){
    // OBTENEMOS EL PDF DE LA RECETA
    const existingPdfBytes = await fetch(pdfReceta).then(res => res.arrayBuffer())
    // CARGAMOS EL PDF EN ARRAYBUFFER
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    // EXTRAEMOS LA FUENTE DEL PDF
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Courier)
    const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.CourierBold)
    // OBTENEMOS LA PRIMER PAGINA DEL PDF
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    // OBTENEMOS LAS MEDIDAS DEL PDF
    const { width, height } = firstPage.getSize()

    // AGREGAMOS EL NOMBRE DEL PACIENTE
    firstPage.drawText(`${data.nombrePaciente}`, {
        x: 170,
        y: height/2 + 249,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })

    // AGREGAMOS LA FECHA DE LA RECETA
    let fechaHoy = new Date();
    let dia = fechaHoy.getDate();
    let mes = fechaHoy.getMonth() + 1;
    let anio = fechaHoy.getFullYear().toString().substr(-2);
    firstPage.drawText(`${dia}`, {
        x: width - 105,
        y: height/2 + 249,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`${mes}`, {
        x: width - 70,
        y: height/2 + 249,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`${anio}`, {
        x: width - 40,
        y: height/2 + 249,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })

    // AGREGAMOS LA EDAD, PESO Y TALLA (FALTA EL SEXO)
    firstPage.drawText(`${data.edad}`, {
        x: 50,
        y: height/2 + 232,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    let coordinatesX;
    if(data.sexo === "F"){
        coordinatesX = 108;
    } else {
        coordinatesX = 120;
    }
    firstPage.drawText(`X`, {
        x: coordinatesX,
        y: height/2 + 232,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`${data.peso}`, {
        x: 178,
        y: height/2 + 232,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`${data.talla}`, {
        x: 279,
        y: height/2 + 232,
        size: 13,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })

    // AGREGAMOS EL NOMBRE DE LA CONSULTA Y SU TRATAMIENTO QUE ELIGIÓ EL PACIENTE
    // firstPage.drawText(`${data.nombreServicio}`, {
    //     x: 40,
    //     y: height/2 + 213,
    //     size: 13,
    //     font: helveticaFont,
    //     color: rgb(0.1, 0.1, 0.1),
    // })
    // firstPage.drawText(`${data.nombreServicioMotivo}`, {
    //     x: 40,
    //     y: height/2 + 196,
    //     size: 13,
    //     font: helveticaFont,
    //     color: rgb(0.1, 0.1, 0.1),
    // })

    // AGREGAMOS EL NOMBRE DEL DOCTOR QUE EMITIÓ LA RECETA, SU CÉDULA Y FIRMA
    const pngImageBytes = await fetch(`/images/firmas/${data.firmaDoctor}`).then(res => res.arrayBuffer())
    const pngImage = await pdfDoc.embedPng(pngImageBytes)
    const pngDims = pngImage.scale(0.18)
    firstPage.drawImage(pngImage, {
        x: width - 140,
        y: height - 120,
        width: pngDims.width,
        height: pngDims.height,
    })
    firstPage.drawText(`Dr. ${data.nombreDoctor}`, {
        x: 17,
        y: height - 65,
        size: 10,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`Cédula: ${data.cedulaDoctor}`, {
        x: 17,
        y: height - 85,
        size: 10,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    firstPage.drawText(`${data.nombreEscuelaDoctor}`, {
        x: 17,
        y: height - 105,
        size: 10,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1),
    })
    
    // AGREGAMOS EL LOGO DE LA ESCUELA DEL DOCTOR
    const pngImageBytesLogo = await fetch(`/images/escuelas/${data.logoEscuela}`).then(res => res.arrayBuffer())
    const pngImageLogo = await pdfDoc.embedPng(pngImageBytesLogo)
    const pngDimsLogo = pngImageLogo.scale(0.03)
    firstPage.drawImage(pngImageLogo, {
        x: width - 250,
        y: height - 67,
        width: pngDimsLogo.width,
        height: pngDimsLogo.height,
    })

    // AGREGAMOS LOS MEDICAMENTOS QUE ELIGIÓ EL PACIENTE
    data.medicamentosReceta.forEach((medicamento, index) => {
        firstPage.drawText(`${medicamento.nombre}`, {
            x: 40,
            y: height/2 + 175 - (index * 120),
            size: 13,
            font: helveticaFontBold,
            color: rgb(0.1, 0.1, 0.1),
        })
        firstPage.drawText(`${medicamento.presentacion}`, {
            x: 270,
            y: height/2 + 175 - (index * 120),
            size: 13,
            font: helveticaFontBold,
            color: rgb(0.1, 0.1, 0.1),
        })
        firstPage.drawText(`Cantidad: ${medicamento.cantidad}`, {
            x: 350,
            y: height/2 + 175 - (index * 120),
            size: 10,
            font: helveticaFont,
            color: rgb(0.1, 0.1, 0.1),
        })
        firstPage.drawText(`Cada ${medicamento.cantidad_hora} hrs`, {
            x: 430,
            y: height/2 + 175 - (index * 120),
            size: 10,
            font: helveticaFont,
            color: rgb(0.1, 0.1, 0.1),
        })
        firstPage.drawText(`Durante ${medicamento.duracion_dias} días`, {
            x: 500,
            y: height/2 + 175 - (index * 120),
            size: 10,
            font: helveticaFont,
            color: rgb(0.1, 0.1, 0.1),
        })
    })

    // AGREGAMOS LAS OBSERVACIONES PARA EL PACIENTE
    let j = 1;
    let observaciones = "";
    for(let i = 0; i < data.observaciones.length; i++){
        if( i === 84 * j ){
            j++
            observaciones += `\n`
        }
        observaciones += data.observaciones[i]
    }
    firstPage.drawText(`Observaciones:\n${observaciones}`, {
        x: 17,
        y: 280,
        size: 11,
        font: helveticaFont,
        color: rgb(0.1, 0.1, 0.1)
    })
    
    // AGREGAMOS EL QR
    const pngImageQr = await pdfDoc.embedPng(data.base64Qr)
    const pngDimsQr = pngImageQr.scale(0.14)
    firstPage.drawImage(pngImageQr, {
        x: 20,
        y: 50,
        width: pngDimsQr.width,
        height: pngDimsQr.height,
    })

    j = 1;
    let newId = "";
    for(let i = 0; i < data.uniqueId.length; i++){
        if( i === 70 * j ){
            j++
            newId += `\n`
        }
        newId += data.uniqueId[i]
    }
    firstPage.drawText(`${newId}`, {
        x: 170,
        y: 187,
        size: 10,
        font: helveticaFont,
        lineHeight: 13,
        color: rgb(0.1, 0.1, 0.1),
    })


    // AGREGAR CLAUSULA DE CUMPLIMIENTO
    firstPage.drawText(`Esta prescripción cumple con los requisitos de la NOM-151-SCFI-2016`, {
        x: 170,
        y: 50,
        size: 10,
        font: helveticaFontBold,
        lineHeight: 10,
        color: rgb(0.1, 0.1, 0.1),
    })

    const pdfBytes = await pdfDoc.save()

    var blob = new Blob([pdfBytes], { type: 'application/pdf' });
    return blob;

    // ENVIAR EL PDF POR CORREO
    // window.open(_url);
    // download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
}