import TableElement from './TableElement';

export default function TableBody({ elements }){

    function renderTableElements(){
        return elements.map((element) => <TableElement key={element.idCita} element={element} />);
    }

    return(
        <tbody>

            {   
                renderTableElements()
            }

        </tbody>
    )
}