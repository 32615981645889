import React from "react";
import "./Login.css";
import { validateEmail } from "./../../validators/validateEmail";
import logo from "./../../logo.png";
import fetchData from "./../../services/fetchData";
import Swal from 'sweetalert2'
import config from "./../../config";

export default function Login({setUser}){

    // Estado del manejo de errores en el input
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    // Función que hará para validar todos los input
    const handleErrors = (values)=>{
        const err = {};
        if(!values.email){
            err.email = "El correo es obligatorio";
        }else if(!validateEmail(values.email)){
            err.email = "El email no es válido";
        }
        if(!values.password){
            err.password = "La contraseña es obligatoria";
        }
        setErrors(err);
        return (err && Object.keys(err).length === 0) ? false : true;
    }

    // Función que manejará el comportamiento del login
    const handleSubmitLogin = async (e) =>{
        e.preventDefault();
        let values = new FormData(e.target)
        let objectValues = Object.fromEntries(values);
        if(handleErrors(objectValues)){
          return;
        }
        setLoading(true);
        fetchData('POST', `${config.urlApi}usuario/login/`, objectValues)
          .then((usuario)=>{

            if(usuario.response === "error"){
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: usuario.message,
              })
              return;
            }
            
            localStorage.setItem('user', JSON.stringify(usuario[0]));
            setUser({
                isLoggedIn: true,
                typeUser: usuario[0].id_tipo_usuario,
                user: usuario[0]
            })
          }).catch((error)=>{
            console.log(error)
            Swal.fire({
              icon: 'error',
              title: '',
              text: 'Ocurrió un error en el servidor, inténtalo de nuevo',
            })
          });

        setLoading(false);
    }

    // Función que borra un error del input
    const cleanError = (e) =>{
        setErrors({...errors, [e.target.name]: ""})
    }

    return (
        <div className="login">
            <div className="login__background" />
            <div className="login__welcomeText">
                <img src={logo} alt="Hospitelec logo"/>
                <p>Sitio de administración y atención al paciente</p>
            </div>

            <div className="login__form">
                <form onSubmit={ handleSubmitLogin }>
                    <input 
                        className={`input ${(errors.email !== "" && errors.email !== undefined) && "input-error"}`} 
                        type="email" 
                        placeholder="Correo electrónico" 
                        name="email" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.email } </small>
                    
                    <input 
                        className={`input ${(errors.password !== "" && errors.password !== undefined) && "input-error"}`} 
                        type="password" 
                        placeholder="Contraseña" 
                        name="password" 
                        onChange={ cleanError } />
                    <small className="error"> { errors.password } </small>

                    <button className="btn" type="submit" disabled={loading}>Entrar</button>
                    <a href="#">¿Olvidaste tu contraseña?</a>
                </form>
            </div>

            <div className="login__footer">
                <p>© 2022 HPE</p>
                <a href="mailto:ricardosandoval@cursodontic.com">Contactar al desarrollador</a>
            </div>

        </div>
    );
}